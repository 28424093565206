import React, { useRef, useState, useEffect} from 'react'
import Modal from 'react-modal';
import { Link } from 'react-router-dom'
import { collection, addDoc } from "firebase/firestore";
import {db} from '../firebase';
import { useNavigate} from 'react-router-dom'
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import emailjs from '@emailjs/browser';
import { Select, Option } from "@material-tailwind/react";
import mainImage from "./images/E-Registration-Title.png"
import background from './images/E-Registration-BG.jpg'
import { Fade } from "react-awesome-reveal";
import Map from "./images/Map of Fullerton Precinct.jpg"
import button from "./images/Will attend button.png"
import nabutton from "./images/Unable to attend button.png"

export const Test = () => {
    
    const [loaded, setLoaded] = useState(false);

    return (
        <div className='pb-20'>
             <ToastContainer/>
            <div className='grid grid-cols-12 container mx-auto '>     
            <div className='text-center col-span-1 md:col-span-1'></div>
                <div className='col-span-10 md:col-span-10 '>
                <div class="w-full container mx-auto">
                    <img className='imgmaxwidth' src={mainImage} onLoad={() => setLoaded(true)}/>
                </div>
                </div>
                <div className='col-span-1 md:col-span-1'></div>
           
        </div>
        <div className='px-8 text-center bg-white w-full text-blue-800 py-8 text-lg'>
                <p className='text-2xl pb-2'>Vopak APPEC Cocktail Reception 2024 Registration</p>
                <p className='text-lg pb-2 leading-tight'>9<sup>th</sup> September 2024, Monday | 6.45pm - 10.30pm</p>
                <p>The Fullerton Hotel Singapore</p>
                <p className='text-sm leading-tight pb-4'>1 Fullerton Square, Singapore 049178, The Ballroom, B1, Lower Lobby</p>
                <img src={Map} className='mapmaxwidth text-center mx-auto w-10/12 pb-2'></img>
                <p className=''>Please RSVP by 23 Aug 2024.</p>
                <p className='font-bold py-1'>To Register:</p>
                <Link to="/attending"><img className="w-64 mx-auto" src={button}/></Link>
                <Link to="/notattending"><img className='w-96 pb-2 mx-auto' src={nabutton}/></Link>
            </div>
        </div>
        
);
};

export default Test
