import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";
import {db} from '../firebase';
import { Link, useNavigate} from 'react-router-dom'
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import emailjs from '@emailjs/browser';
import { Select, Option } from "@material-tailwind/react";
import mainImage from "./images/E-Registration-Title.png"
import background from './images/E-Registration-BG.jpg'
import { Fade } from "react-awesome-reveal";


const NotAttending = () => {

    
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const myStyle = {
        backgroundImage: `url(${
            background
        })`,
        height: "100vh",
        marginTop: "-70px",
        fontSize: "50px",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
    };
      
    const [clickedYet, setClickedYet] = useState(false);
    
    const form = useRef();

    const navigate = useNavigate();


    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
    
        const Days = [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    
        var currentdate = new Date(); 
    
        let h = currentdate.getHours();
        h = h < 10 ? "0" + h : h;
        let m = currentdate.getMinutes();
        m = m < 10 ? "0" + m : m;
        let s = currentdate.getSeconds();
        s = s < 10 ? "0" + s : s;
    
    
        var datetime =  Days[currentdate.getDay()] + ", "
                        + currentdate.getDate() + " "
                        + monthNames[currentdate.getMonth()] + " "
                        + currentdate.getFullYear() + ", "  
                        + h + ":"  
                        + m + ":" 
                        + s;
  

    const colRef = collection(db, 'vopakappec')
    const doneRef = collection(db, 'vopakappecregistered')
    const [formData, setFormData] = useState({
        timestamp:datetime
    })
    const [seats, setSeats] = useState(1)

    
    function handleSeats(count) {
        setSeats(count)
    }


    async function updateTicket(e) {
        e.preventDefault()
        addDoc(colRef, {formData})   
  };

  const [registeredEmail, setRegisteredEmail] = useState("")
  const [expanded, setExpanded] = React.useState(true);

  

  console.log(form)
  async function goNext(e) {
    e.preventDefault()
    console.log(registeredEmail)
    const emailRef = query(colRef, where("registeredEmail", "==", registeredEmail.toLowerCase()));
    let data
    let name 
    let email
    let salutation = ""
    await getDocs(emailRef)
    .then((snapshot) => {
        snapshot.docs.forEach((doc) => {
            data = doc.data().registeredEmail
            if (doc.data().salutation !== undefined && doc.data().salutation !== 'undefined') {
                document.getElementById("salutation").value = doc.data().salutation 
                salutation = doc.data().salutation
            } else {
                document.getElementById("salutation").value = ""
            }
            document.getElementById("name").value = doc.data().name 
            name = doc.data().name

            console.log(form.current)
        })
    }) 
    if (data === undefined) {
        toast.error('Your email address is not on the guest list yet. To express interest to attend this event or clarification, please contact us at vopaksg@vtsappec2024.com.', {
            toastId: 'error1',
            position: "top-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            return
    } 
        try {
            console.log(data)
            await emailjs.sendForm('service_dq7nbhr', 'template_owmlrc7', form.current, 'r04-bQl4Di3WPya_p')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            })
            formData.attending = "No"
            formData.name = name
            formData.salutation = salutation
            await addDoc(doneRef, {formData})    
            localStorage.setItem('registeredEmail', formData.registeredEmail.toLowerCase())
            navigate('/naconfirmation')
        } catch(error) {
            console.log(error)
        }
   
  }

  return (
    <div className='py-8 px-4 max-w-7xl mx-auto'>
      <ToastContainer className="w-96"/>
        <p className='text-5xl text-white text-center pt-8 pb-20'>REGISTRATION PAGE</p>
        <form ref={form} onSubmit={goNext} className='bg-white p-6 rounded-lg'>
            <p className='py-2 text-lg'>Registration (Not Attending)</p>
            <div className='border solid border-1 border-gray-400 md:flex mt-4 rounded-lg w-full'>
                <p className='md:text-base text-sm ml-auto py-2 md:py-6 rounded-lg px-2'>Please enter the registered email address </p>
                <input name="email" type="email" value={formData.registeredEmail} onChange={(e) => {setClickedYet(true); setFormData({...formData, registeredEmail: e.target.value}); setRegisteredEmail(e.target.value)}} className='mx-2 inputdog box-border mb-4 md:my-5 focus:outline-none focus:border-blue-700 focus:ring-1 focus:ring-blue-700 p-1 rounded-lg md:mr-2 border solid border-gray-300' required></input>
            </div>
            <input
        type="hidden"
        className="form-control"
        name="salutation"
        id="salutation"
      />
                  <input
        type="hidden"
        className="form-control"
        name="name"
        id="name"
      />
            <div className='text-right mt-8 py-4'>
            <button className='rounded-lg py-4 text-white px-24 bg-green-500 ml-auto text-right'>Register</button>
            </div>
           
        </form>
    </div>
  )
}

export default NotAttending
