import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";
import {db} from '../firebase';
import { Link, useNavigate} from 'react-router-dom'
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import emailjs from '@emailjs/browser';
import { Select, Option } from "@material-tailwind/react";
import mainImage from "./images/E-Registration-Title.png"
import background from './images/E-Registration-BG.jpg'
import { Fade } from "react-awesome-reveal";


const Attending = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

      
    const [clickedYet, setClickedYet] = useState(false);
    const [registeredEmail, setRegisteredEmail] = useState("")
    
    const form = useRef();

    const navigate = useNavigate();

    const colRef = collection(db, 'vopakappec')
    const [formData, setFormData] = useState({
        noTickets: 1
    })
    const [seats, setSeats] = useState(1)


  async function goNext(e) {
    e.preventDefault()
    console.log(registeredEmail.toLowerCase())
    const emailRef = query(colRef, where("registeredEmail", "==", registeredEmail.toLowerCase()));
    let data
    let getsalu
    await getDocs(emailRef)
    .then((snapshot) => {
        snapshot.docs.forEach((doc) => {
            data = doc.data().registeredEmail
            getsalu = doc.data().salutation
            console.log(doc.data().registeredEmail)
    })
        if (data === undefined) {
            toast.error('Your email address is not on the guest list yet. To express interest to attend this event or clarification, please contact us at vopaksg@vtsappec2024.com.', {
                toastId: 'error1',
                position: "top-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
        } else {
            localStorage.setItem('registeredEmail', formData.registeredEmail.toLowerCase())
            localStorage.setItem('noTickets', formData.noTickets)
            localStorage.setItem('salutation', getsalu)
            navigate('/details')
        }
    }) 
  }



  return (
    <div>


    <div className='py-8 px-4 max-w-7xl mx-auto'>
    <ToastContainer className="w-96"/>
        <p className='text-5xl text-white text-center pt-8 pb-20'>REGISTRATION PAGE</p>
        <form onSubmit={goNext} className='bg-white p-6 rounded-lg'>
            <p className='py-2 text-lg'>Registration</p>
            <div className='bg-blue-100 solid border border-blue-300 rounded-lg p-4 flex'>
                <p className='lg:text-base text-sm py-2 text-blue-600' >Vopak APPEC Ticket</p> 
                <select className='lg:text-base text-sm ml-auto text-right rounded-xl' onChange={(e) => {setFormData({...formData, noTickets: e.target.value})}} value={formData.noTickets} required>
                <option value={1} color="gray" >1</option>
                </select>
            </div>
            <div className='border solid border-1 border-gray-400 md:flex mt-4 justify-normal rounded-lg'>
                <p className='md:text-base text-sm ml-auto py-2 md:py-6 rounded-lg px-2'>Please enter your email address </p>
                <input type="email" value={formData.registeredEmail} onChange={(e) => {setClickedYet(true); setFormData({...formData, registeredEmail: e.target.value}); setRegisteredEmail(e.target.value)}} className='mx-2 inputdog box-border mb-4 md:my-5 focus:outline-none focus:border-blue-700 focus:ring-1 focus:ring-blue-700 p-1 rounded-lg md:mr-2 border solid border-gray-300' required></input>
            </div>
            <div className='text-right mt-8 py-4'>
            <button className='rounded-lg py-4 text-white px-24 bg-green-500 ml-auto text-right'>Register</button>
            </div>
            
        </form>
       

            {/*<form ref={form} onSubmit={sendEmail} className='grid grid-cols-12 container pt-12 mx-auto '>
                <div className='col-span-1 md:col-span-1'></div>
                <div className='col-span-10 md:col-span-10 '>
                <div class="w-full container mx-auto">
                    <img className='w-full' src={mainImage} onLoad={() => setLoaded(true)}/>
                </div>
                </div>
                <div className='col-span-1 md:col-span-1'></div>

                <div className='col-span-2 md:col-span-3'></div>
                {loaded ? <div className='container mx-auto col-span-8 md:col-span-6 '>
                    <div>
                        <label className="pt-2 text-white">Name<span className='text-red-600'>*</span></label>
                        <br/>
                        <input style={{borderColor: "#efd089"}}  name="name" type="text" onInput={(e) => setFormData({...formData, name: e.target.value})} value={formData.name} className="w-full bg-white border-1"/>
                    </div>
                    <div className="pt-3">
                        <label className='text-white'>Division<span className='text-red-600'>*</span></label>
                        <br/>
                        <select  style={{borderColor: "#efd089"}}  name="division" type="text" onChange={(e) => setFormData({...formData, division: e.target.value})} value={formData.division} className="w-full bg-white border-1">
                            <option value='option1'>Option 1</option>
                            <option value='option2'>Option 2</option>
                            <option value='option2'>Option 3</option>
                        </select>
                    </div>
                    <div className="pt-3">
                        <label className='text-white'>Email<span className='text-red-600'>*</span></label>
                        <br/>
                        <input  style={{borderColor: "#efd089"}}  name="email" type="text" onInput={(e) => setFormData({...formData, email: e.target.value})} value={formData.email} className="w-full bg-white border-1"/>
                    </div>
                    <div className='pt-3 text-white'>
                        <label>Are you attending?<span className='text-red-600'>*</span></label>
                        <div className="flex items-center mb-4">
                            <input onClick={() => handleAttending(true)} onInput={(e) => {setFormData({...formData, attending: e.target.value}); console.log(e.tareg)}} id="attending-option-1" type="radio" name="attending" value="Yes" className="w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600  " required/>
                            <label htmlFor="attending-option-1" className="ml-2  font-medium text-white ">
                            Yes
                            </label>
                            <input onClick={() => {handleAttending(false); handleAllergy(false)}} onInput={(e) => setFormData({...formData, attending: e.target.value})} id="attending-option-2" type="radio" name="attending" value="Yes" className="ml-4 w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600 " required/>
                            <label htmlFor="attending-option-2" className="ml-2  font-medium text-white ">
                            No
                            </label>
                        </div>
                    </div>
                    { isAttending ? <div>
                    <div className='pt-3'>
                        <label>Please select your preffered menu:<span className='text-red-600'>*</span></label>
                        <fieldset>
                        <div className="items-center mb-4">
                            
                            <input onInput={(e) => setFormData({...formData, dietary: e.target.value})} id="dietary-option-1" type="radio" name="dietary" value="Yes" className="w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600  " required/>
                            <label htmlFor="dietary-option-1" className="ml-2">
                            Chinese Set
                            </label>
                            <br/>
                            <input onInput={(e) => setFormData({...formData, dietary: e.target.value})} id="dietary-option-2" type="radio" name="dietary" value="Yes" className="w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600 " required/>
                            <label htmlFor="dietary-option-2" className="ml-2 ">
                            Muslim Set
                            </label>
                            <br/>
                            <input onInput={(e) => setFormData({...formData, dietary: e.target.value})} id="dietary-option-3" type="radio" name="dietary" value="Yes" className="w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600 " required/>
                            <label htmlFor="dietary-option-3" className="ml-2">
                            Vegetarian Set
                            </label>
                            <br/>
                            <input onInput={(e) => setFormData({...formData, dietary: e.target.value})} id="dietary-option-4" type="radio" name="dietary" value="Yes" className="w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600 " required/>
                            <label htmlFor="dietary-option-4" className="ml-2">
                            Indian Vegetarian Set
                            </label>
                        </div>
                        </fieldset>
                    </div>
                    <div className='pt-3'>
                        <label>Food Allergies (If any)</label>
                        <fieldset>
                        <div className="items-center mb-4">
                            <input onClick={() => handleAllergy(false)} onInput={(e) => setFormData({...formData, allergy: e.target.value})} id="allergy-option-1" type="radio" name="allergy" value="Yes" className="w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600  " required/>
                            <label htmlFor="allergy-option-1" className="ml-2 ">
                            Shell Fish
                            </label>
                            <br/>
                            <input onClick={() => handleAllergy(false)} onInput={(e) => setFormData({...formData, allergy: e.target.value})} id="allergy-option-2" type="radio" name="allergy" value="Yes" className="w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600 " required/>
                            <label htmlFor="allergy-option-2" className="ml-2">
                            Nuts
                            </label>
                            <br/>
                            <input onClick={() => handleAllergy(true)} onInput={(e) => setFormData({...formData, allergy: e.target.value})} id="allergy-option-3" type="radio" name="allergy" value="Yes" className="w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600 " required/>
                            <label htmlFor="allergy-option-3" className="ml-2">
                                Others (Please specify)
                            </label>
                        </div>
                        </fieldset>
                    </div></div>: <></>}
 
                    <div>
                        {isAttending && isOthers ? <input name="firstName" type="text" onInput={(e) => setFormData({...formData, allergyremarks: e.target.value})} value={formData.firstName} className="w-full bg-white border-red-600 border-1"/> : <></>}
                    </div>
                    <div className='mx-auto text-center'>
                    <button className='mt-6 w-40 mx-auto deeventcolorbg py-2 px-3 text-white'>Submit</button>
                    </div>
               </div> : <></>}
            </form>*/}
    </div>
    </div>
  )
}

export default Attending
