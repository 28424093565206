import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router, Routes, Route,  } from 'react-router-dom'
import Form from './components/Form';
import Attending from './components/Attending';
import Details from './components/Details'
import Confirmation from './components/Confirmation';
import NotAttending from './components/NotAttending';
import NotAttendingConfirmation from './components/NotAttendingConfirmation';
import Download from './components/Download';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Routes>
      <Route exact path='/' element={< Form />}></Route>  
      <Route exact path='/attending' element={< Attending />}></Route>  
      <Route exact path='/notattending' element={< NotAttending />}></Route>  
      <Route exact path='/details' element={< Details />}></Route>  
      <Route exact path='/confirmation' element={< Confirmation />}></Route>  
      <Route exact path='/naconfirmation' element={< NotAttendingConfirmation />}></Route>  
      <Route exact path='/download' element={< Download />}></Route>  
    </Routes>
  </Router>
);