import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { collection, getDocs, query, where, doc as docSnapshot, updateDoc } from "firebase/firestore";
import {db} from '../firebase';
import { FaCheckCircle } from "react-icons/fa";
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import { FaLocationDot } from "react-icons/fa6";
import { CiCalendar } from "react-icons/ci";

const Confirmation = () => {

    const colRef = collection(db, 'vopakappec')
    const name = localStorage.getItem("name")
    let salutation = "" 
    if (localStorage.getItem("salutation") !== "undefined" && localStorage.getItem("salutation") !== undefined) {
        salutation = localStorage.getItem("salutation")
        console.log("tarasdsadasdas")
    } else {
        salutation = "" 
    }
    const company = localStorage.getItem("company")
    const timeStamp = localStorage.getItem("timeStamp")
    const email = localStorage.getItem("email")
    const tickets = localStorage.getItem('noTickets')
    const dietary = localStorage.getItem("dietary")
    let data
    const [refNo, setRefNo] = useState()

    const [id, setId] = useState()


  return (
    <div className='py-8 px-4 max-w-7xl mx-auto'>
        <p className='text-5xl text-white text-center pt-8 pb-20'>REGISTRATION PAGE</p>
        <div className='bg-white p-6 rounded-lg'>
        <FaCheckCircle color='#22C564' size={80}className='mx-auto'/>
        <div className='text-center'>
        <p className='text-2xl py-6'>Registration is completed</p>
        <p className='mb-8'>You will receive a confirmation email shortly.</p>
        </div>
        <div className='border rounded-lg'>
            <hr className='w-full'></hr>
            <div className='lg:flex p-4'>
            <p className='py-2' >Registration Status</p>
            <p className='lg:ml-auto py-2 text-sm rounded-xl w-24 text-white text-center bg-green-500'>APPROVED</p>
            </div>
            <hr className='w-full'></hr>
            <div className='p-4'>
            <p className='py-2 underline' >Registration Details</p>
            <p className='py-2'>Name: {salutation} {name}</p>
            <p className='py-2'>Email: {email}</p>
            <p className='py-2'>Company: {company}</p>
    <p className='py-2'>Dietary Requirements: {dietary}</p>
            <p className='py-2'>No. of Tickets: {tickets}</p>
            </div>
            <hr className='w-full'></hr>
            <div className='p-4'>
            <p className='py-4'>You registered on {timeStamp}</p>
            </div>
        </div>
        <div className='mt-8 border rounded-lg py-4'>
            <div className='p-4'>
            <p className='text-base md:text-lg'>Vopak APPEC Cocktail Reception 2024</p>
            <div className='text-xs md:text-base flex py-2'>
                <CiCalendar size={20} className='mr-2'/>
            <p>9<superscript>th</superscript> September 2024, Monday | 6.45pm - 10.30pm</p>
            </div>
                <div className='text-xs md:text-base flex pb-4'>
                <FaLocationDot size={20} className='mr-2'/>
                <p>The Fullerton Hotel Singapore</p>
            </div>
        <AddToCalendarButton

            name="Vopak APPEC Cocktail Reception 2024"
            options={['Apple','Google']}
            location="The Fullerton Hotel Singapore"
            startDate="2024-09-09"
            endDate="2024-09-09"
            startTime="18:15"
            endTime="22:30"
            timeZone="Singapore"
            ></AddToCalendarButton>
        </div>
        </div>

        </div>
      

            {/*<form ref={form} onSubmit={sendEmail} className='grid grid-cols-12 container pt-12 mx-auto '>
                <div className='col-span-1 md:col-span-1'></div>
                <div className='col-span-10 md:col-span-10 '>
                <div class="w-full container mx-auto">
                    <img className='w-full' src={mainImage} onLoad={() => setLoaded(true)}/>
                </div>
                </div>
                <div className='col-span-1 md:col-span-1'></div>

                <div className='col-span-2 md:col-span-3'></div>
                {loaded ? <div className='container mx-auto col-span-8 md:col-span-6 '>
                    <div>
                        <label className="pt-2 text-white">Name<span className='text-red-600'>*</span></label>
                        <br/>
                        <input style={{borderColor: "#efd089"}}  name="name" type="text" onInput={(e) => setFormData({...formData, name: e.target.value})} value={formData.name} className="w-full bg-white border-1"/>
                    </div>
                    <div className="pt-3">
                        <label className='text-white'>Division<span className='text-red-600'>*</span></label>
                        <br/>
                        <select  style={{borderColor: "#efd089"}}  name="division" type="text" onChange={(e) => setFormData({...formData, division: e.target.value})} value={formData.division} className="w-full bg-white border-1">
                            <option value='option1'>Option 1</option>
                            <option value='option2'>Option 2</option>
                            <option value='option2'>Option 3</option>
                        </select>
                    </div>
                    <div className="pt-3">
                        <label className='text-white'>Email<span className='text-red-600'>*</span></label>
                        <br/>
                        <input  style={{borderColor: "#efd089"}}  name="email" type="text" onInput={(e) => setFormData({...formData, email: e.target.value})} value={formData.email} className="w-full bg-white border-1"/>
                    </div>
                    <div className='pt-3 text-white'>
                        <label>Are you attending?<span className='text-red-600'>*</span></label>
                        <div className="flex items-center mb-4">
                            <input onClick={() => handleAttending(true)} onInput={(e) => {setFormData({...formData, attending: e.target.value}); console.log(e.tareg)}} id="attending-option-1" type="radio" name="attending" value="Yes" className="w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600  " required/>
                            <label htmlFor="attending-option-1" className="ml-2  font-medium text-white ">
                            Yes
                            </label>
                            <input onClick={() => {handleAttending(false); handleAllergy(false)}} onInput={(e) => setFormData({...formData, attending: e.target.value})} id="attending-option-2" type="radio" name="attending" value="Yes" className="ml-4 w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600 " required/>
                            <label htmlFor="attending-option-2" className="ml-2  font-medium text-white ">
                            No
                            </label>
                        </div>
                    </div>
                    { isAttending ? <div>
                    <div className='pt-3'>
                        <label>Please select your preffered menu:<span className='text-red-600'>*</span></label>
                        <fieldset>
                        <div className="items-center mb-4">
                            
                            <input onInput={(e) => setFormData({...formData, dietary: e.target.value})} id="dietary-option-1" type="radio" name="dietary" value="Yes" className="w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600  " required/>
                            <label htmlFor="dietary-option-1" className="ml-2">
                            Chinese Set
                            </label>
                            <br/>
                            <input onInput={(e) => setFormData({...formData, dietary: e.target.value})} id="dietary-option-2" type="radio" name="dietary" value="Yes" className="w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600 " required/>
                            <label htmlFor="dietary-option-2" className="ml-2 ">
                            Muslim Set
                            </label>
                            <br/>
                            <input onInput={(e) => setFormData({...formData, dietary: e.target.value})} id="dietary-option-3" type="radio" name="dietary" value="Yes" className="w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600 " required/>
                            <label htmlFor="dietary-option-3" className="ml-2">
                            Vegetarian Set
                            </label>
                            <br/>
                            <input onInput={(e) => setFormData({...formData, dietary: e.target.value})} id="dietary-option-4" type="radio" name="dietary" value="Yes" className="w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600 " required/>
                            <label htmlFor="dietary-option-4" className="ml-2">
                            Indian Vegetarian Set
                            </label>
                        </div>
                        </fieldset>
                    </div>
                    <div className='pt-3'>
                        <label>Food Allergies (If any)</label>
                        <fieldset>
                        <div className="items-center mb-4">
                            <input onClick={() => handleAllergy(false)} onInput={(e) => setFormData({...formData, allergy: e.target.value})} id="allergy-option-1" type="radio" name="allergy" value="Yes" className="w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600  " required/>
                            <label htmlFor="allergy-option-1" className="ml-2 ">
                            Shell Fish
                            </label>
                            <br/>
                            <input onClick={() => handleAllergy(false)} onInput={(e) => setFormData({...formData, allergy: e.target.value})} id="allergy-option-2" type="radio" name="allergy" value="Yes" className="w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600 " required/>
                            <label htmlFor="allergy-option-2" className="ml-2">
                            Nuts
                            </label>
                            <br/>
                            <input onClick={() => handleAllergy(true)} onInput={(e) => setFormData({...formData, allergy: e.target.value})} id="allergy-option-3" type="radio" name="allergy" value="Yes" className="w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600 " required/>
                            <label htmlFor="allergy-option-3" className="ml-2">
                                Others (Please specify)
                            </label>
                        </div>
                        </fieldset>
                    </div></div>: <></>}
 
                    <div>
                        {isAttending && isOthers ? <input name="firstName" type="text" onInput={(e) => setFormData({...formData, allergyremarks: e.target.value})} value={formData.firstName} className="w-full bg-white border-red-600 border-1"/> : <></>}
                    </div>
                    <div className='mx-auto text-center'>
                    <button className='mt-6 w-40 mx-auto deeventcolorbg py-2 px-3 text-white'>Submit</button>
                    </div>
               </div> : <></>}
            </form>*/}
    </div>
  )
}

export default Confirmation
