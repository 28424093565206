import React, {useState, useEffect} from 'react'
import { collection, getDocs, query, where, doc as docSnapshot, updateDoc } from "firebase/firestore";
import {db} from '../firebase';
import { FaCheckCircle } from "react-icons/fa";

const NotAttendingConfirmation = () => {

    const colRef = collection(db, 'vopakappec')
    const name = localStorage.getItem("name")
    const company = localStorage.getItem("company")
    const timeStamp = localStorage.getItem("timeStamp")
    const email = localStorage.getItem("email")
    const tickets = localStorage.getItem('noTickets')
    let data
    const [refNo, setRefNo] = useState()
    const [id, setId] = useState()


  return (
    <div className='py-8 px-4 max-w-7xl mx-auto'>
    <p className='text-5xl text-white text-center pt-8 pb-20'>REGISTRATION PAGE</p>
    <div className='bg-white p-6 rounded-lg'>
    <FaCheckCircle color='#22C564' size={80}className='mx-auto'/>
    <div className='text-center'>
    <p className='text-2xl py-6'>Registration is completed</p>
    <p className='mb-8'>We have noted that you will not be attending the event. Should you wish to amend your registration details, please email <a className="underline text-blue-600" href="mailto:vopaksg@vtsappec2024.com">vopaksg@vtsappec2024.com</a></p>
    </div>

    </div>
</div>
  )
}

export default NotAttendingConfirmation
