import React from 'react'
import {db} from '../firebase';
import { collection, getDocs, query, deleteDoc, doc as docSnapshot, where, orderBy, limit, addDoc, updateDoc  } from "firebase/firestore";
import * as xlsx from 'xlsx';
import * as XLSX from 'xlsx';
import { Upload } from '@mui/icons-material';

const Download = () => {
    const docRef = query(collection(db, "vopakappecregistered"))
    const downloadLatest = () => {
        getDocs(docRef)
            .then((snapshot) => {
                let information = []
                snapshot.docs.forEach((doc) => {
                  information.push(doc.data().formData)
            })
            let data = information
            const worksheet = XLSX.utils.json_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
            XLSX.writeFile(workbook, "vopakappec.xlsx");
        })
        .catch(err => {
            console.log(err.message)
          })
      };

      const readUploadFile = (e) => {
        e.preventDefault();
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = xlsx.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = xlsx.utils.sheet_to_json(worksheet);
                json.forEach(element => {
                    element['registered'] = 'No'
                    if (element['DEPARTMENT'] === undefined) {

                    }
                    if (element['TABLE'] === undefined) {

                    }
                    console.log(element['REMARKS'])
                    addDoc(collection(db, "vopakappec"), element)
                });
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    }


    
  return (
    <div><button className='absolute text-white' onClick={downloadLatest}>Download</button><label for="file-upload" className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2'>
    <input
             type="file"
             name="upload"
             id="upload"
             onChange={readUploadFile}
          />
          Upload New Data
          </label></div>
  )
}

export default Download