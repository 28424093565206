import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { collection, doc, addDoc, query, where, getDocs, updateDoc } from "firebase/firestore";
import {db} from '../firebase';
import { Link, useNavigate} from 'react-router-dom'
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import emailjs from '@emailjs/browser';
import { Select, Option } from "@material-tailwind/react";
import mainImage from "./images/E-Registration-Title.png"
import background from './images/E-Registration-BG.jpg'
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from '@mui/material/Fade';

const Attending = () => {
    const [expanded, setExpanded] = React.useState(true);

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
    
        const Days = [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    
        var currentdate = new Date(); 
    
        let h = currentdate.getHours();
        h = h < 10 ? "0" + h : h;
        let m = currentdate.getMinutes();
        m = m < 10 ? "0" + m : m;
        let s = currentdate.getSeconds();
        s = s < 10 ? "0" + s : s;
    
    
        var datetime =  Days[currentdate.getDay()] + ", "
                        + currentdate.getDate() + " "
                        + monthNames[currentdate.getMonth()] + " "
                        + currentdate.getFullYear() + ", "  
                        + h + ":"  
                        + m + ":" 
                        + s;

    const handleExpansion = () => {
      setExpanded((prevExpanded) => !prevExpanded);
    };
  

    const steps = [
        'Registration',
        'Complete',
      ];
  
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const myStyle = {
        backgroundImage: `url(${
            background
        })`,
        height: "100vh",
        marginTop: "-70px",
        fontSize: "50px",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
    };
      

    const form = useRef();

    const navigate = useNavigate();

    const colRef = collection(db, 'vopakappec')
    const doneRef = collection(db, 'vopakappecregistered')
    const [formData, setFormData] = useState({
        registeredEmail: localStorage.getItem("registeredEmail"),
        noTickets: localStorage.getItem("noTickets"),
        salutation: localStorage.getItem("salutation"),
        timestamp: datetime
    })

    
    async function sendEmail(e) {
        console.log(form)
        e.preventDefault()
        localStorage.setItem("name", formData.name)
        localStorage.setItem("company", formData.company)
        localStorage.setItem("email", formData.email.toLowerCase())
        localStorage.setItem("timeStamp", datetime)
        localStorage.setItem("dietary", formData.dietary)
        formData.attending = "Yes"
        const emailRef = query(colRef, where("registeredEmail", "==", localStorage.getItem('registeredEmail')));
        let data
        let user 
        await getDocs(emailRef)
        .then((snapshot) => {
            snapshot.docs.forEach((doc) => {
                data = doc.data().registeredEmail
                if (doc.data().salutation !== undefined && doc.data().salutation !== 'undefined') {
                    document.getElementById("salutation").value = doc.data().salutation 
                } else {
                    document.getElementById("salutation").value = ""
                }

            })
        })

        await addDoc(doneRef, {formData})   
        
        try {
            try {   
                await emailjs.sendForm('service_dq7nbhr', 'template_6qaomc5', form.current, 'r04-bQl4Di3WPya_p')
                .then((result) => {
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                })
                navigate('/confirmation')
            } catch(e) {
                console.log(e)
                toast.error('Registration was unsuccessful, please try again.', {
                    position: "top-center",
                    autoClose: false,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: 0,
                    theme: "light",
                    })
                return
            }
        } catch(e) {
            console.log(e)
            toast.error('Registration was unsuccessful, please try again.', {
                position: "top-right",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: 0,
                theme: "light",
                })
            return
        }
     

  };


  const [isAttending, setIsAttending] = useState(true)
  const [isOthers, setIsOthers] = useState(false)

  const handleAttending = (bool) => {
    setIsAttending(bool)
  }

  const handleAllergy = (bool) => {
    setIsOthers(bool)
  }

  return (
    <form ref={form} onSubmit={sendEmail} className='py-8 px-4 max-w-7xl mx-auto'>
        <p className='text-5xl text-white text-center pt-8 pb-20'>REGISTRATION PAGE</p>
        <div className='bg-white p-6 rounded-lg'>
        <Box className="mx-auto py-6">
      <Stepper activeStep={0} >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel className='text-black'>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
    <Accordion
        expanded={expanded}
        onChange={handleExpansion}
        slots={{ transition: Fade }}
        slotProps={{ transition: { timeout: 400 } }}
        sx={{
          '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
          '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
        }}
      >
        <AccordionSummary
            style={{"border": "", "backgroundColor": "lightgray"}}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <p className='lg:text-base text-sm py-2' >Vopak APPEC Ticket</p> 
        </AccordionSummary>
        <AccordionDetails>
        <div className='p-4'>
            <label className="pt-2 text-gray-600">Full Name<span className='text-red-600'>*</span></label>
            <br/>
            <input name="name" type="text" onInput={(e) => setFormData({...formData, name: e.target.value})} value={formData.name} className="w-full bg-white border-1" required/>
        </div>
        <div className='p-4'>
            <label className="pt-2 text-gray-600">Email<span className='text-red-600'>*</span></label>
            <br/>
            <input name="email" type="email" onInput={(e) => setFormData({...formData, email: e.target.value})} value={formData.email} className="w-full bg-white border-1" required/>
        </div>
        <div className='p-4'>
            <label className="pt-2 text-gray-600">Company Name<span className='text-red-600'>*</span></label>
            <br/>
            <input name="Company Name" type="text" onInput={(e) => setFormData({...formData, company: e.target.value})} value={formData.company} className="w-full bg-white border-1" required/>
        </div>
        <div className='p-4'>
            <label className="pt-2 text-gray-600">Dietary Requirements<span className='text-red-600'>*</span></label>
            <br/>
            <input name="dietary" type="text" onInput={(e) => setFormData({...formData, dietary: e.target.value})} value={formData.dietary} className="w-full bg-white border-1" placeholder='[Your dietary requirements here]'required/>
        </div>
        <input
        type="hidden"
        className="form-control"
        name="salutation"
        id="salutation"
      />

        </AccordionDetails>
      </Accordion>
            <div className='text-right mt-8 py-4'>
            <button to="/confirmation" className='rounded-lg py-4 px-4 text-white bg-green-500 ml-auto text-right'>Proceed with Registration</button>
            </div>
        
        </div>

            {/*<form ref={form} onSubmit={sendEmail} className='grid grid-cols-12 container pt-12 mx-auto '>
                <div className='col-span-1 md:col-span-1'></div>
                <div className='col-span-10 md:col-span-10 '>
                <div class="w-full container mx-auto">
                    <img className='w-full' src={mainImage} onLoad={() => setLoaded(true)}/>
                </div>
                </div>
                <div className='col-span-1 md:col-span-1'></div>

                <div className='col-span-2 md:col-span-3'></div>
                {loaded ? <div className='container mx-auto col-span-8 md:col-span-6 '>
                    <div>
                        <label className="pt-2 text-white">Name<span className='text-red-600'>*</span></label>
                        <br/>
                        <input style={{borderColor: "#efd089"}}  name="name" type="text" onInput={(e) => setFormData({...formData, name: e.target.value})} value={formData.name} className="w-full bg-white border-1"/>
                    </div>
                    <div className="pt-3">
                        <label className='text-white'>Division<span className='text-red-600'>*</span></label>
                        <br/>
                        <select  style={{borderColor: "#efd089"}}  name="division" type="text" onChange={(e) => setFormData({...formData, division: e.target.value})} value={formData.division} className="w-full bg-white border-1">
                            <option value='option1'>Option 1</option>
                            <option value='option2'>Option 2</option>
                            <option value='option2'>Option 3</option>
                        </select>
                    </div>
                    <div className="pt-3">
                        <label className='text-white'>Email<span className='text-red-600'>*</span></label>
                        <br/>
                        <input  style={{borderColor: "#efd089"}}  name="email" type="text" onInput={(e) => setFormData({...formData, email: e.target.value})} value={formData.email} className="w-full bg-white border-1"/>
                    </div>
                    <div className='pt-3 text-white'>
                        <label>Are you attending?<span className='text-red-600'>*</span></label>
                        <div className="flex items-center mb-4">
                            <input onClick={() => handleAttending(true)} onInput={(e) => {setFormData({...formData, attending: e.target.value}); console.log(e.tareg)}} id="attending-option-1" type="radio" name="attending" value="Yes" className="w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600  " required/>
                            <label htmlFor="attending-option-1" className="ml-2  font-medium text-white ">
                            Yes
                            </label>
                            <input onClick={() => {handleAttending(false); handleAllergy(false)}} onInput={(e) => setFormData({...formData, attending: e.target.value})} id="attending-option-2" type="radio" name="attending" value="Yes" className="ml-4 w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600 " required/>
                            <label htmlFor="attending-option-2" className="ml-2  font-medium text-white ">
                            No
                            </label>
                        </div>
                    </div>
                    { isAttending ? <div>
                    <div className='pt-3'>
                        <label>Please select your preffered menu:<span className='text-red-600'>*</span></label>
                        <fieldset>
                        <div className="items-center mb-4">
                            
                            <input onInput={(e) => setFormData({...formData, dietary: e.target.value})} id="dietary-option-1" type="radio" name="dietary" value="Yes" className="w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600  " required/>
                            <label htmlFor="dietary-option-1" className="ml-2">
                            Chinese Set
                            </label>
                            <br/>
                            <input onInput={(e) => setFormData({...formData, dietary: e.target.value})} id="dietary-option-2" type="radio" name="dietary" value="Yes" className="w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600 " required/>
                            <label htmlFor="dietary-option-2" className="ml-2 ">
                            Muslim Set
                            </label>
                            <br/>
                            <input onInput={(e) => setFormData({...formData, dietary: e.target.value})} id="dietary-option-3" type="radio" name="dietary" value="Yes" className="w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600 " required/>
                            <label htmlFor="dietary-option-3" className="ml-2">
                            Vegetarian Set
                            </label>
                            <br/>
                            <input onInput={(e) => setFormData({...formData, dietary: e.target.value})} id="dietary-option-4" type="radio" name="dietary" value="Yes" className="w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600 " required/>
                            <label htmlFor="dietary-option-4" className="ml-2">
                            Indian Vegetarian Set
                            </label>
                        </div>
                        </fieldset>
                    </div>
                    <div className='pt-3'>
                        <label>Food Allergies (If any)</label>
                        <fieldset>
                        <div className="items-center mb-4">
                            <input onClick={() => handleAllergy(false)} onInput={(e) => setFormData({...formData, allergy: e.target.value})} id="allergy-option-1" type="radio" name="allergy" value="Yes" className="w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600  " required/>
                            <label htmlFor="allergy-option-1" className="ml-2 ">
                            Shell Fish
                            </label>
                            <br/>
                            <input onClick={() => handleAllergy(false)} onInput={(e) => setFormData({...formData, allergy: e.target.value})} id="allergy-option-2" type="radio" name="allergy" value="Yes" className="w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600 " required/>
                            <label htmlFor="allergy-option-2" className="ml-2">
                            Nuts
                            </label>
                            <br/>
                            <input onClick={() => handleAllergy(true)} onInput={(e) => setFormData({...formData, allergy: e.target.value})} id="allergy-option-3" type="radio" name="allergy" value="Yes" className="w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600 " required/>
                            <label htmlFor="allergy-option-3" className="ml-2">
                                Others (Please specify)
                            </label>
                        </div>
                        </fieldset>
                    </div></div>: <></>}
 
                    <div>
                        {isAttending && isOthers ? <input name="firstName" type="text" onInput={(e) => setFormData({...formData, allergyremarks: e.target.value})} value={formData.firstName} className="w-full bg-white border-red-600 border-1"/> : <></>}
                    </div>
                    <div className='mx-auto text-center'>
                    <button className='mt-6 w-40 mx-auto deeventcolorbg py-2 px-3 text-white'>Submit</button>
                    </div>
               </div> : <></>}
            </form>*/}
    </form>
  )
}

export default Attending
